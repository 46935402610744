import { FileUploadFormatProps } from '@/hooks/useSingleFileUpload'

export type signedUrlData = {
    fileName: string
    signedUrl: string
    path: string
}

export type signedUrlContext = {
    signedUrl: string
    file: FileUploadFormatProps
    id: string
}
export type VaultListData = {
    name: string
    owner: string
    org: string
    is_private: boolean
    createdAt: string
    updatedAt: string
    id: string
}

export type VaultItem = {
    name: string
    type: string
    vault: string
    parent: string
    owner: string
    org: string
    url: string
    createdAt: string
    updatedAt: string
    id: string
}

export type VaultCategory = {
    name: string
    org: string
    owner: string
    createdAt: string
    updatedAt: string
    id: string
}

export enum VaultItemVisibility {
    PERSONAL = 'personal',
    SHARED = 'shared',
    GENERAL = 'general',
}

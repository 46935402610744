import { CircleArrow } from '@/assets/assets.export'
import ToggleSwitch from '@/components/Switch'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { EditIcon } from 'lucide-react'
import { useState } from 'react'
import { MdDelete } from 'react-icons/md'
import {
    ApporvalConditionOperatorEnum,
    ApprovalPolicy,
    approversCount,
    SupportedPolicyConsts,
} from '@/types/approval-policy'
import DeletePolicyPrompt from '../Modals/ApprovalPolicy/DeletePolicyPrompt'
import TogglePolicyPrompt from '../Modals/ApprovalPolicy/TogglePolicyPrompt'
import CreatePolicyModal from '../Modals/ApprovalPolicy/CreatePolicyModal/CreatePolicyModal'

interface ApprovalPolicyModalProps {
    policy: ApprovalPolicy
    module: SupportedPolicyConsts
    prevVersion?: boolean
}

const ApprovalPolicyCardDetails = ({ policy, module, prevVersion }: ApprovalPolicyModalProps) => {
    const [isEdit, setIsEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [showToggle, setShowToggle] = useState(false)

    const hasDeactivatedUser = policy?.approval_levels?.some(approvers =>
        approvers?.approvers?.some(approver => approver?.status === 'DEACTIVATED')
    )

    return (
        <div className='mt-2'>
            <section className={`${prevVersion ? 'opacity-60' : ''} `}>
                <div className={` flex justify-between flex-col-reverse lg:flex-row gap-7 lg:gap-0 items-start ${''}`}>
                    <div className='flex flex-col gap-6 text-[#0f0b0b] w-[90%]'>
                        <div className=' text-lg font-medium  gap-2'>
                            <span className='flex items-center justify-between sm:justify-normal gap-2'>
                                <span>{policy?.policy_name}</span>

                                <span className={`ml-3 text-[#5E5E5E] px-3 py-2 rounded-full text-xs `}>
                                    {policy?.createdAt && format(new Date(policy?.createdAt), 'dd/MM/yyyy')}
                                </span>
                            </span>
                            <p className='mt-1 text-sm  text-gray7 '>
                                Assign admins with wallet access to approve expense and reimbursments{' '}
                            </p>
                        </div>

                        <div className='flex gap-3 flex-wrap'>
                            {policy?.approval_levels.map(level => {
                                return (
                                    <div
                                        key={level?.id}
                                        className={`px-4 py-[10px] rounded-lg flex flex-col justify-between bg-[#F9F9F9] `}
                                    >
                                        <h2 className='text-lg font-medium flex items-center gap-2'>
                                            {level?.operator === ApporvalConditionOperatorEnum.BETWEEN ? (
                                                <>
                                                    <span className='bg-white px-4 py-2 text-center rounded-lg'>
                                                        {SupportedPolicyConsts.leave === module
                                                            ? level?.min_amount
                                                            : formatAmount(level?.min_amount ?? 0)}
                                                    </span>
                                                    <CircleArrow />
                                                </>
                                            ) : (
                                                capitalizeText(level?.operator?.replaceAll('_', ' '))
                                            )}
                                            <span className='bg-white px-4 py-2 text-center rounded-lg'>
                                                {SupportedPolicyConsts.leave === module
                                                    ? level?.max_amount
                                                    : formatAmount(level.max_amount ?? level?.amount)}
                                            </span>
                                        </h2>
                                        <div className='flex mt-1  justify-between gap-4 flex-wrap '>
                                            {level.approvers.length > 0 &&
                                                level.approvers?.map((approver, i) => {
                                                    return (
                                                        <span
                                                            className='text-xs font-medium mr-2 flex items-center gap-1'
                                                            key={i}
                                                        >
                                                            <span className='text-brand'>
                                                                {i + 1}

                                                                {approversCount?.[
                                                                    (i + 1) as keyof typeof approversCount
                                                                ] ?? 'th'}
                                                            </span>
                                                            <span className='opacity-90'>
                                                                {capitalizeText(approver?.user?.first_name)}{' '}
                                                                {capitalizeText(approver?.user?.last_name[0])}.
                                                            </span>
                                                        </span>
                                                    )
                                                })}
                                            {level.approvers?.length === 0 && (
                                                <span className='mr-3'>Auto-approval</span>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}

                            <div className={`px-4 py-[10px] rounded-lg flex flex-col justify-between bg-[#F9F9F9] `}>
                                <span className='w-fit text-brand bg-brand/10 text-xs rounded-full px-2 p-1'>
                                    Fall-back approvers
                                </span>
                                <div className='grid grid-cols-2 gap-2'>
                                    {policy?.fallback_approval_level?.approvers?.map((approver, i) => (
                                        <div
                                            className='flex mt-1  justify-between gap-4 flex-wrap '
                                            key={approver._id ?? approver.id}
                                        >
                                            <span className='text-xs font-medium mr-2 flex items-center gap-1'>
                                                <span className='text-brand'>
                                                    {i + 1}
                                                    {approversCount?.[(i + 1) as keyof typeof approversCount] ?? 'th'}
                                                </span>
                                                <span className='opacity-90'>
                                                    {capitalizeText(approver?.user?.first_name)}{' '}
                                                    {capitalizeText(approver?.user?.last_name[0])}.
                                                </span>
                                            </span>
                                        </div>
                                    ))}
                                </div>

                                {policy?.fallback_approval_level?.auto_approval && (
                                    <span className='mr-3'>Auto-approval</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='flex items-center gap-4 justify-end lg:justify-normal ml-auto'>
                        {policy?.active && (
                            <p className='text-xs mr-2 font-semibold text-brightGreen py-2 px-3 rounded-[64px] bg-lightGreen'>
                                Active
                            </p>
                        )}
                        <div
                            className='rotate-180'
                            onClick={() => {
                                if (!policy?.active) {
                                    setShowToggle(true)
                                }
                            }}
                        >
                            {!hasDeactivatedUser && <ToggleSwitch checked={policy?.active} />}
                        </div>
                        <EditIcon
                            color='#838383'
                            onClick={() => setIsEdit(true)}
                            className='cursor-pointer'
                            size={23}
                        />
                        <MdDelete
                            color='red'
                            size={25}
                            className='cursor-pointer'
                            onClick={() => setDeleteModal(true)}
                        />
                    </div>
                </div>
            </section>
            {deleteModal && <DeletePolicyPrompt closeModal={setDeleteModal} policyId={policy?._id} />}
            {showToggle && <TogglePolicyPrompt module={module} closeModal={setShowToggle} policy={policy} />}
            {isEdit && (
                <CreatePolicyModal
                    module={module}
                    edit={true}
                    closeModal={() => setIsEdit(false)}
                    updatePolicy={policy}
                />
            )}
            {hasDeactivatedUser && (
                <p className='bg-errorRed/10 rounded p-3 w-fit text-gray7 font-medium text-xs mt-4'>
                    <span className='block text-errorRed text-base '>Attention</span>
                    You can’t activate this policy as an approver is a deactivated user.{' '}
                    <button
                        onClick={() => setIsEdit(true)}
                        className='font-semibold text-sm underline underline-offset-2 text-black opacity-80'
                    >
                        Replace this approver
                    </button>{' '}
                    to fix this.
                </p>
            )}
        </div>
    )
}

export default ApprovalPolicyCardDetails

import { FolderIcon, ShareAccessIcon } from '@/assets/assets.export'
import { FaEllipsisVertical } from 'react-icons/fa6'
import { Menu } from '@headlessui/react'
import { PenLine } from 'lucide-react'
import { FileDown } from 'lucide-react'
import { MdOutlineMoveToInbox } from 'react-icons/md'
import { Trash2 } from 'lucide-react'
import { useMemo, useState } from 'react'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { DeleteModal } from '@/components/Modals/Vault/DeleteModal'
import { ShareAccessModal } from '@/components/Modals/Vault/ShareAccessModal'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useAuthContext, useVaultContext } from '@/context'
import { VaultItem } from '@/services/vault/types'
import { differenceInHours, format, formatDistanceToNow } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { MoveFileModal } from '@/components/Modals/Vault/MoveFileModal'
import { useNavigate } from 'react-router-dom'

type FolderContainerProps = {
    folder: VaultItem
}
export const FolderContainer = ({ folder }: FolderContainerProps) => {
    const [showRename, setShowRename] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showShareAccess, setShowShareAccess] = useState(false)
    const [showMove, setShowMove] = useState(false)
    const navigate = useNavigate()
    const { setSelectedFolder, selectedFolder } = useVaultContext()

    const { selectedAccount } = useAuthContext()
    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const notInRootVault = folder?.parent !== folder?.vault

    const orgMembers = useMemo(() => employees ?? [], [employees])
    const owner = orgMembers.find(memb => memb?.id === folder.owner)

    return (
        <div
            className='bg-offwhite border border-gray8 p-3 rounded-xl cursor-pointer'
            onClick={() => {
                const isAParent = folder.parent === folder.vault
                if (isAParent) {
                    setSelectedFolder(folder)
                }
                navigate(`?folder=${folder?.id}${folder.parent !== folder.vault ? `&parent=${folder.parent}` : ''}`)
            }}
        >
            <div className='flex justify-between gap-2'>
                <div className='flex items-center gap-2'>
                    <FolderIcon />
                    <p className='text-xs font-medium truncate w-[85%]'>{capitalizeText(folder?.name)}</p>
                </div>
                <Menu as='div' className='relative'>
                    <Menu.Button
                        className=''
                        onClick={e => {
                            e.stopPropagation()
                        }}
                    >
                        <FaEllipsisVertical color='#A4A4A4' />
                    </Menu.Button>
                    <Menu.Items
                        onClick={e => {
                            e.stopPropagation()
                        }}
                        className='shadow-xl w-[185px] flex flex-col absolute -right-9 p-3 px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'
                    >
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    setShowRename(true)
                                }}
                            >
                                <PenLine size={16} />
                                Rename folder
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {}}
                            >
                                <FileDown size={16} />
                                Download as zip
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    setShowShareAccess(true)
                                }}
                            >
                                <ShareAccessIcon className='scale-[.9]' />
                                Share access
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm'
                                onClick={() => {
                                    setShowMove(true)
                                }}
                            >
                                <MdOutlineMoveToInbox size={16} className='-rotate-90' />
                                Move folder
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <div className='border-t border-gray8 mt-1'>
                                <span
                                    className='px-4 text-errorRed py-2 cursor-pointer hover:bg-[#f3f3f3] flex items-center gap-2 text-sm mt-1'
                                    onClick={() => {
                                        setShowDelete(true)
                                    }}
                                >
                                    <Trash2 size={16} />
                                    Delete folder
                                </span>
                            </div>
                        </Menu.Item>
                    </Menu.Items>
                </Menu>
            </div>
            <div className='flex flex-col gap-2 mt-3'>
                <div className='flex justify-between items-center text-xs'>
                    <p className='text-gray7 font-medium'>Owned by</p>
                    <p className='text-[#202020] font-semibold'>
                        {capitalizeText(owner?.user?.first_name)} {capitalizeText(owner?.user?.last_name)}
                    </p>
                </div>
                <div className='flex justify-between items-center text-xs'>
                    <p className='text-gray7 font-medium'>Created</p>
                    <p className='text-[#202020] font-semibold truncate w-[100px] text-right'>
                        {differenceInHours(new Date(), new Date(folder?.createdAt)) > 23
                            ? format(new Date(folder?.createdAt), 'MMM dd, yyyy')
                            : formatDistanceToNow(new Date(folder?.createdAt), { addSuffix: true })}
                    </p>
                </div>
                <div className='flex justify-between items-center text-xs'>
                    <p className='text-gray7 font-medium'>Parent folder</p>
                    <p className='text-[#202020] font-semibold'>
                        {!notInRootVault ? 'Root Vault' : selectedFolder?.name}
                    </p>
                </div>
            </div>
            {showRename && <CreateNewFolder closeModal={setShowRename} rename={true} item={folder} />}
            {showDelete && <DeleteModal closeModal={setShowDelete} file={folder} fileType={'folder'} />}
            {showShareAccess && (
                <ShareAccessModal closeModal={setShowShareAccess} fileType='folder' orgMembers={orgMembers} />
            )}
            {showMove && <MoveFileModal closeModal={setShowMove} file={folder} actionType={'move'} />}
        </div>
    )
}

// import downArrow from '@/assets/down-arrow.svg'
import { Button } from '@/components'
import AssignLeaveModal from '@/components/Modals/Payroll/LeaveRequest/AssignLeaveModal'
import ProgressBar from '@/components/ProgressBar'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, usePayrollContext } from '@/context'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useApproveLeave, useUpdateLeave } from '@/services/payroll/mutations'
import { useGetLeaveAnalytics, useGetLeaveById } from '@/services/payroll/queries'
import { LeaveRequestType } from '@/services/payroll/types'
import { ADMIN_LEAVE_PERMISSIONS } from '@/utils/permisson-helper'

import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { ArrowLeft } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function LeaveMoreDetails() {
    const navigate = useNavigate()
    const { selectedLeaveData, setSelectedLeaveData, assignLeaveModal, setAssignLeaveModal } = usePayrollContext()
    const { selectedAccount } = useAuthContext()
    const [updateOption, setUpdateOption] = useState('')

    const hasPermission = useHasAccessPermisson(ADMIN_LEAVE_PERMISSIONS)

    const { leave_id } = useParams()
    const { data, isLoading } = useGetLeaveById({
        queryParams: {
            leave_id: leave_id as string,
        },
    })

    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])
    const { mutate: updateLeaveFn, isLoading: isUpdatingLeave } = useUpdateLeave({
        queryParams: { leave_id: leave_id as string },
        onSuccess: data => {
            setSelectedLeaveData(prev => {
                return prev ? { ...prev, status: (data as LeaveRequestType).status } : undefined
            })
            toastHandler({ message: 'Leave has been declined', state: 'success' })
        },
    })

    const { mutate: approveLeaveFn, isLoading: isApprovingLeave } = useApproveLeave({
        queryParams: {
            leave_id: leave_id as string,
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: data => {
            setSelectedLeaveData(prev => {
                return prev ? { ...prev, status: (data as LeaveRequestType).status } : undefined
            })
            toastHandler({ message: 'Leave has been approved', state: 'success' })
        },
    })
    const leaveData = useMemo(() => selectedLeaveData ?? data, [data, selectedLeaveData])

    const { data: leaveAnalytics, isLoading: isGettingLeaveAnalytics } = useGetLeaveAnalytics({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            account: leaveData?.account?._id as string,
        },
    })

    const calculateDifference = (date1: string, date2: string) => {
        const parsedDate1 = new Date(date1)
        const parsedDate2 = new Date(date2)

        if (!isNaN(parsedDate1.getTime()) && !isNaN(parsedDate2.getTime())) {
            const timeDifference = parsedDate2.getTime() - parsedDate1.getTime()
            const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
            return daysDifference
        }
    }

    const leave_details = [
        {
            name: 'Name',
            desc: `${leaveData?.account?.user?.first_name} ${leaveData?.account?.user?.last_name}`,
        },
        { name: 'Type', desc: `${capitalizeText(leaveData?.leave_type)}` },
        {
            name: 'Leave period',
            desc:
                leaveData?.start_date &&
                `${format(new Date(leaveData?.start_date?.slice(-1)?.[0]), 'dd/MM/yyyy')} - ${format(
                    new Date(leaveData?.end_date?.slice(-1)?.[0]),
                    'dd/MM/yyyy'
                )}`,
        },
        {
            name: 'Days count',
            desc:
                leaveData?.start_date &&
                `${calculateDifference(
                    leaveData?.start_date as unknown as string,
                    leaveData?.end_date as unknown as string
                )} days`,
        },
        {
            name: 'Description',
            desc: `${capitalizeText(leaveData?.reason) ?? 'N/A'}`,
        },
        {
            name: 'Status',
            desc: (
                <span
                    className={`${
                        leaveData?.status === 'approved'
                            ? 'text-[#19C37D]'
                            : leaveData?.status === 'pending'
                              ? 'text-[#A89011]'
                              : 'text-[#CF491E]/90'
                    }`}
                >
                    {capitalizeText(leaveData?.status)}
                </span>
            ),
        },
        {
            name: 'Request date',
            desc: leaveData?.createdAt && format(new Date(leaveData?.createdAt), 'dd/MM/yyyy'),
        },
        { name: 'Approved date', desc: 'N/A' },
        { name: 'Approved by', desc: 'N/A' },
    ]

    if (isLoading) return <ProgressBar />

    return (
        <div className='h-screen overflow-scroll'>
            <section className='mx-[1.4rem] lg:mx-[2.7rem] py-3 pt-2 mb-6 '>
                <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                    <ArrowLeft />
                    back
                </div>
                <section className=' bg-white rounded-xl px-7 py-6 pb-11 mb-24'>
                    <div className='border-b border-[#DADCE0] pb-3 flex flex-col lg:flex-row gap-5 lg:gap-0 items-start lg:items-center justify-between'>
                        <h2 className=' font-semibold text-lg'>
                            {leave_details[0].desc} - {leave_details[1].desc}
                        </h2>
                    </div>
                    {false && (
                        <div className='bg-[#F8F1DE] p-3 mt-6 rounded-xl'>
                            <h1 className='text-[#202020] font-semibold'>Attention. Missing details</h1>
                            <p className='text-sm text-[#5E5E5E] mt-1'>
                                This user has exhausted their Vacation leave allowance
                                <span className='font-semibold'> (15 days)</span>.
                            </p>
                        </div>
                    )}
                    <div className='flex flex-wrap max-w-[60rem] gap-x-[5rem] gap-y-8 mt-8 '>
                        {leave_details.map((detail, idx) => (
                            <>
                                <div
                                    className={`'flex flex-col ${detail.name === 'Description' ? 'w-[80%]' : ''}`}
                                    key={idx}
                                >
                                    <h4 className='text-md font-semibold mb-1'>{detail.name}</h4>
                                    <span className='text-[#5E5E5E] text-[1rem]'>{detail.desc}</span>
                                </div>
                                {detail.name === 'Description' && <br className='hidden lg:block' />}
                            </>
                        ))}
                    </div>
                    {!hasPermission && !(leaveData?.status === 'approved' || leaveData?.status === 'declined') && (
                        <div className='flex justify-end mt-10 w-3/4'>
                            <div className='flex gap-5'>
                                <Button className='bg-[#CF491E]  min-w-[130px] min-h-[35px] hover'>Cancel leave</Button>
                            </div>
                        </div>
                    )}
                    {hasPermission && leaveData?.status == 'pending' && (
                        <div className='flex flex-col lg:flex-row gap-3 lg:gap-0 justify-between mt-10 w-full lg:w-3/4'>
                            {/* <div className='flex gap-5'> */}
                            <Button
                                className='bg-transparent text-[#202020] border border-[#202020] hover:bg-transparent  min-w-[130px] min-h-[35px] hover'
                                onClick={() => setAssignLeaveModal(true)}
                            >
                                Edit
                            </Button>
                            <div className='flex w-full lg:w-auto justify-between lg:justify-normal gap-5'>
                                <Button
                                    className='bg-[#CF491E] w-full lg:w-auto  min-w-[130px] min-h-[35px] bg-transparent text-errorRed hover:bg-transparent border-[#CF491E] border'
                                    onClick={() => {
                                        setUpdateOption('decline')
                                        updateLeaveFn({ status: 'declined' })
                                    }}
                                    loading={updateOption === 'decline' && isUpdatingLeave}
                                    disabled={isUpdatingLeave}
                                >
                                    Decline
                                </Button>
                                <Button
                                    loading={isApprovingLeave}
                                    disabled={isUpdatingLeave || isApprovingLeave}
                                    className='w-full lg:w-auto min-w-[130px] min-h-[35px] hover'
                                    onClick={() => {
                                        setUpdateOption('approve')
                                        approveLeaveFn({})
                                    }}
                                >
                                    Approve
                                </Button>
                            </div>
                            {/* </div> */}
                        </div>
                    )}

                    {isGettingLeaveAnalytics ? (
                        <ProgressBar />
                    ) : (
                        <div className='mt-16'>
                            <div className='border-b border-[#DADCE0] pb-3'>
                                <h2 className=' font-semibold text-lg'>Leave balance</h2>
                            </div>
                            <div className='mt-6'>
                                {Number(leaveAnalytics?.user_leave_days?.leave_types?.length) > 0 ? (
                                    <div className='grid grid-cols-2 md:grid-cols-5 gap-4 w-ful lg:w-[90%]'>
                                        {leaveAnalytics?.user_leave_days?.leave_types?.map(leaveData => (
                                            <div className='border border-[#DADCE0] bg-[#F4FAFF] rounded-lg py-[10px] pl-[10px] lg:pl-[21px] flex flex-col gap-3 h-[104px]'>
                                                <span className='text-[#5E5E5E]'>
                                                    {capitalizeText(leaveData?.leave_type)}
                                                </span>
                                                <span className='text-3xl font-semibold '>
                                                    {leaveData?.days_remaining}{' '}
                                                    <span className='text-base font-medium'>days</span>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className='grid grid-cols-2 md:grid-cols-5 gap-4 w-ful lg:w-[90%]'>
                                        <div className='border border-[#DADCE0] bg-[#F4FAFF] rounded-lg py-[10px] pl-[10px] lg:pl-[21px] flex flex-col gap-3 h-[104px]'>
                                            <span className='text-[#5E5E5E]'>Vacation</span>
                                            <span className='text-3xl font-semibold '>
                                                {leaveAnalytics?.org_leave_days?.vacation}{' '}
                                                <span className='text-base font-medium'>days</span>
                                            </span>
                                        </div>
                                        <div className='border border-[#DADCE0] bg-[#FFEFE7] rounded-lg py-[10px] pl-[10px] lg:pl-[21px] flex flex-col gap-3 h-[104px]'>
                                            <span className='text-[#5E5E5E]'>Sick days</span>
                                            <span className='text-3xl font-semibold '>
                                                {leaveAnalytics?.org_leave_days?.sick_days}{' '}
                                                <span className='text-base font-medium'>days</span>
                                            </span>
                                        </div>
                                        <div className='border border-[#DADCE0] bg-[#F4FFFA] rounded-lg py-[10px] pl-[10px] lg:pl-[21px] flex flex-col gap-3 h-[104px]'>
                                            <span className='text-[#5E5E5E]'>Maternity / Paternity</span>
                                            <span className='text-3xl font-semibold '>
                                                {leaveAnalytics?.org_leave_days?.parental}{' '}
                                                <span className='text-base font-medium'>days</span>
                                            </span>
                                        </div>
                                        <div className='border border-[#DADCE0] bg-[#EFEFF9] rounded-lg py-[10px] pl-[10px] lg:pl-[21px] flex flex-col gap-3 h-[104px]'>
                                            <span className='text-[#5E5E5E]'>Personal & Conference</span>
                                            <span className='text-3xl font-semibold '>
                                                {Number(leaveAnalytics?.org_leave_days?.miscellaneous) +
                                                    Number(leaveAnalytics?.org_leave_days?.conference)}{' '}
                                                <span className='text-base font-medium'>days</span>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </section>
                {assignLeaveModal && (
                    <AssignLeaveModal
                        closeModal={setAssignLeaveModal}
                        orgMembers={orgMembers}
                        edit={true}
                        leaveData={leaveData as LeaveRequestType}
                    />
                )}
            </section>
        </div>
    )
}

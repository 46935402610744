import { ProfileEditIcon } from '@/assets/assets.export'
import { useAuthContext } from '@/context'
import { useGetLeaveSettings } from '@/services/payroll/queries'
import React, { useState } from 'react'
import { leaveDays } from '../../helper'
import ProgressBar from '@/components/ProgressBar'
import LeaveGenerationSetting from '../Settings/partials/LeaveGenerationSetting'
import HolidaySettings from '../Settings/partials/HolidaySettings'

const LeaveDaysSettings = () => {
    const [edit, setEdit] = useState(false)
    const { selectedAccount } = useAuthContext()
    const {
        data: leaveSettings,
        isLoading,
        isRefetching: isRefetchingLeaveSettings,
    } = useGetLeaveSettings({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    if (isLoading)
        return (
            <div className='grid place-items-center'>
                <ProgressBar allWidth={false} />
            </div>
        )
    return (
        <div>
            {!edit && (
                <div
                    className={`p-5 rounded-xl border border-gray5 ${
                        isRefetchingLeaveSettings ? 'opacity-50' : 'opacity-100'
                    }`}
                >
                    <div className='flex justify-between items-center mb-4'>
                        <div className=''>
                            <h2 className='text-lg font-medium'>Leave management</h2>
                            <p className='text-[14px] text-[#5E5E5E]  mt-1'>Set leave allowances for all your users</p>
                        </div>
                        <span
                            className='flex items-center gap-2 text-[#5E5E5E] border-[#DADCE0] rounded-md p-2 border px-4 cursor-pointer'
                            onClick={() => setEdit(true)}
                        >
                            <ProfileEditIcon />
                            <button type='button'>Edit</button>
                        </span>
                    </div>

                    <div className='flex gap-5 lg:gap-12 flex-wrap'>
                        <div className=''>
                            <h2 className='text-[#838383] text-[15px]'>Sick days</h2>
                            <h2 className='text-base font-medium mt-2'>
                                {leaveSettings?.[0]?.sick_days}{' '}
                                {Number(leaveSettings?.[0]?.sick_days) > 1 ? 'days' : 'day'} in a year
                            </h2>
                        </div>
                        <div className=''>
                            <h2 className='text-[#838383] text-[15px]'>Vacation</h2>
                            <h2 className='text-base font-medium mt-2'>
                                {
                                    leaveDays.vacation.filter(
                                        data => data.id === leaveSettings?.[0]?.vacation?.toString()
                                    )[0]?.name
                                }{' '}
                                in a year
                            </h2>
                        </div>
                        <div className=''>
                            <h2 className='text-[#838383] text-[15px]'>Personal</h2>
                            <h2 className='text-base font-medium mt-2'>
                                {leaveSettings?.[0]?.miscellaneous}{' '}
                                {Number(leaveSettings?.[0]?.miscellaneous) > 1 ? 'days' : 'day'} in a year
                            </h2>
                        </div>
                        <div className=''>
                            <h2 className='text-[#838383] text-[15px]'>Maternity / Paternity</h2>
                            <h2 className='text-base font-medium mt-2'>
                                {
                                    leaveDays.childSupport.filter(
                                        data => data.id === leaveSettings?.[0]?.parental?.toString()
                                    )[0]?.name
                                }{' '}
                                in a year
                            </h2>
                        </div>
                    </div>
                </div>
            )}
            {edit && <LeaveGenerationSetting leaveSettingsData={leaveSettings?.[0]} setEditLeavSettings={setEdit} />}

            <HolidaySettings />
        </div>
    )
}

export default LeaveDaysSettings

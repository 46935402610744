import InvoiceEmptyState from '@/assets/invoice-empty-state.svg'
import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import { useAuthContext } from '@/context'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { FileDown, PenLine, Trash2 } from 'lucide-react'
import React, { useMemo, useState } from 'react'
import { BsSend } from 'react-icons/bs'
import { VaultItem } from '@/services/vault/types'
import { CreateNewFolder } from '@/components/Modals/Vault/CreateNewFolder'
import { DeleteModal } from '@/components/Modals/Vault/DeleteModal'
import { ShareAccessModal } from '@/components/Modals/Vault/ShareAccessModal'
import { MoveFileModal } from '@/components/Modals/Vault/MoveFileModal'
import { useGetOrgMembers } from '@/services/employees/queries'
import { FolderIcon, PDFIcon } from '@/assets/assets.export'
import { downloadFile } from '@/utils/utils'
import { MdOutlineMoveToInbox } from 'react-icons/md'
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2'
import { useSearchParams } from 'react-router-dom'
import { History } from 'lucide-react'

type VaultTableProps = {
    data: VaultItem[]
}
const VaultListTable = ({ data }: VaultTableProps) => {
    const columnHelper = createColumnHelper<VaultItem>()
    const [showRename, setShowRename] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showShareAccess, setShowShareAccess] = useState(false)
    const [showMove, setShowMove] = useState(false)
    const [moveType, setMoveType] = useState('')
    const [selectedItem, setSelectedItem] = useState<VaultItem>()
    const { selectedAccount } = useAuthContext()
    const { data: employees } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: `ACTIVE`,
        },
    })
    const orgMembers = useMemo(() => employees ?? [], [employees])

    const [rowSelection, setRowSelection] = React.useState({})
    const [searchParams] = useSearchParams()
    const isTrashView = searchParams.get('path') === 'trash'

    const handleSort = () => {
        // setSort?.(sort)
    }

    const columns: ColumnDef<VaultItem, any>[] = [
        columnHelper.accessor('name', {
            header: () => <p>Name</p>,
            cell: cell => (
                <span className='flex items-center gap-2'>
                    {cell.row.original.type === 'folder' ? (
                        <FolderIcon className='h-5 w-6' />
                    ) : (
                        <PDFIcon className='h-6 w-6' />
                    )}
                    {cell.renderValue()}
                </span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('type', {
            header: () => <>Size</>,
            cell: cell => cell.renderValue(),
            enableSorting: true,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p>Uploaded</p>,
            cell: cell => {
                return (
                    <div className=''>
                        <p className='mt-1 text-[#5E5E5E]'>{format(new Date(cell.renderValue()), 'MMM dd, yyyy')} </p>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: row => {
                const isFolder = row?.row?.original?.type === 'folder'
                return (
                    <TableActions
                        actions={[
                            {
                                icon: PenLine,
                                label: `Rename ${isFolder ? 'folder' : 'file'}`,
                                method: () => {
                                    setSelectedItem(row?.row.original)
                                    setShowRename(true)
                                },
                                hidden: isTrashView,
                            },
                            {
                                label: isFolder ? 'Download as zip' : 'Download file',
                                method: () => {
                                    setSelectedItem(row?.row.original)
                                    !isFolder ? downloadFile(row?.row.original.url, row?.row.original.name) : undefined
                                },
                                icon: FileDown,
                                hidden: isTrashView,
                            },
                            {
                                label: isFolder ? 'Share access' : 'Share file',
                                method: () => {
                                    setSelectedItem(row?.row.original)
                                    setShowShareAccess(true)
                                },
                                icon: BsSend,
                                hidden: isTrashView,
                            },
                            {
                                label: 'Duplicate',
                                method: () => {
                                    setMoveType('duplicate')
                                    setSelectedItem(row?.row.original)
                                    setShowMove(true)
                                },
                                icon: HiOutlineDocumentDuplicate,
                                hidden: isFolder || isTrashView,
                            },
                            {
                                label: isFolder ? 'Move folder' : 'Move to',
                                method: () => {
                                    setMoveType('move')
                                    setSelectedItem(row?.row.original)
                                    setShowMove(true)
                                },
                                icon: MdOutlineMoveToInbox,
                                hidden: isTrashView,
                            },
                            {
                                label: isFolder ? 'Delete folder' : 'Delete file',
                                method: () => {
                                    setSelectedItem(row?.row.original)
                                    setShowDelete(true)
                                },
                                icon: Trash2,
                                hidden: isTrashView,
                            },
                            {
                                label: 'Restore file',
                                method: () => {},
                                icon: History,
                                hidden: !isTrashView,
                            },
                            {
                                label: 'Delete forever',
                                method: () => {},
                                icon: Trash2,
                                hidden: !isTrashView,
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    return (
        <section className='h-full'>
            <Table
                data={data}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                // handleRowClicked={handleRowClick}
                rowClickable={false}
                handleSort={handleSort}
                emptyStateDescription={
                    isTrashView ? '' : 'Upload  files or create folder by clicking on the "New" button'
                }
                emptyStateTitle={
                    isTrashView ? 'No file or folder moved to trash yet' : 'No file uploaded or folder created yet'
                }
                emptyStateImg={InvoiceEmptyState}
                // currentPageNumber={pageNumber}
                // setCurrentPageNumber={setPageNumber}
                // pagination_data={pagination_details}
                // setIsPageNumberClicked={setPageNumberClicked}
            />
            {showRename && (
                <CreateNewFolder
                    closeModal={setShowRename}
                    rename={true}
                    item={selectedItem as VaultItem}
                    fileType={selectedItem?.type === 'file' ? 'file' : undefined}
                />
            )}
            {showDelete && (
                <DeleteModal
                    closeModal={setShowDelete}
                    file={selectedItem as VaultItem}
                    fileType={selectedItem?.type as string}
                />
            )}
            {showShareAccess && (
                <ShareAccessModal
                    closeModal={setShowShareAccess}
                    fileType={selectedItem?.type as string}
                    orgMembers={orgMembers}
                />
            )}
            {showMove && (
                <MoveFileModal closeModal={setShowMove} file={selectedItem as VaultItem} actionType={moveType} />
            )}
        </section>
    )
}

export default VaultListTable

import { BackIcon, BusinessDirectoryIcon, DangerIcon, PersonalCardIcon } from '@/assets/assets.export'
import Logo from '@/assets/bizpendlogo.png'
import { useAuthContext } from '@/context'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import useFeatureFlag from '@/lib/feature-flags/useFeatureFlag'
import { cn } from '@/lib/styling'
import { useLogoutUser } from '@/services/auth/mutations'
import { useCheckIfAdminBizpendEmail } from '@/utils/bizpend'
import { IsOwner, isAdmin } from '@/utils/permissions'
import { ACCESS_PERMISSIONS } from '@/utils/permisson-helper'
import { prodEnvironment } from '@/utils/utils'
import { motion } from 'framer-motion'
import { ChevronDown, LogOutIcon } from 'lucide-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
    SideBarProps,
    SidebarItemType,
    accountSidebarPermissions,
    contractSidebarPermissions,
    dashboardRoute,
    payrollSidebarPermissions,
    settingsRoute,
    sidebarRoute,
} from './utils'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/Accordion'

const NewSidebar = ({ onRouteChange, setShowSidebar }: SideBarProps) => {
    const { logoutUser, selectedAccount } = useAuthContext()
    const accessPermission = [
        ...((selectedAccount?.role?.permissions ?? []) as ACCESS_PERMISSIONS[]),
        ACCESS_PERMISSIONS.ACCESS_DASHBOARD,
        ACCESS_PERMISSIONS.ACCESS_SETTINGS,
    ]

    const navigate = useNavigate()
    const hasAdminPerm = isAdmin(selectedAccount!)

    const payrollFlagEnabled = useFeatureFlag('show_payroll')
    const contractFlagEnabled = useFeatureFlag('show_contract')
    const showBankAccounts = useFeatureFlag('show_bank_accounts')

    const hasAccessToAccounts = useHasAccessPermisson(accountSidebarPermissions)
    const hasAccessToOrgPayroll = useHasAccessPermisson(payrollSidebarPermissions)
    const hasAccessToOrgContract = useHasAccessPermisson(contractSidebarPermissions)

    const isBizpendAdmin = useCheckIfAdminBizpendEmail(selectedAccount?.user?.email)

    const pathname = window.location.pathname

    const { mutate: logoutFn } = useLogoutUser({
        onSuccess: () => {
            navigate('/')
            logoutUser()
        },
    })

    const handleLogOut = () => {
        logoutUser()
        logoutFn({})
    }

    return (
        <div className='w-60 border-r border-[#DADCE0] h-screen min-h-screen bg-[#ffffff] flex flex-col overflow-scroll'>
            <div className='flex-1'>
                <div className='px-2 lg:px-6 py-3 border-b border-[#DADCE0] flex items-center justify-between'>
                    <img src={Logo} alt='logo' className='w-[137px]' />
                    <BackIcon
                        className='cursor-pointer hover:bg-slate-100 p-1 rounded block lg:hidden'
                        onClick={() => setShowSidebar?.(false)}
                    />
                </div>

                <div className='py-3 text-sm flex flex-col gap-1 px-3'>
                    {IsOwner() && !selectedAccount?.org?.kyb_completed && (
                        <button
                            className='flex gap-3 px-3 bg-red-100 py-2 items-center'
                            onClick={() => {
                                navigate('/dashboard/settings/company/kyb')
                            }}
                        >
                            <DangerIcon className='stroke-red-500' /> Complete KYB
                        </button>
                    )}

                    <SidebarLink {...dashboardRoute} onRouteChange={onRouteChange} />

                    {sidebarRoute.map(route => {
                        if (!payrollFlagEnabled && route?.key === 'payroll') return
                        if (!contractFlagEnabled && route?.key === 'contracts') return
                        if (!showBankAccounts && route?.key === 'accounts') return
                        if (!hasAccessToAccounts && route?.key === 'accounts') return
                        if (route.key === 'accounting' && prodEnvironment()) return
                        if (route.key === 'vault' && prodEnvironment()) return

                        if (route?.permission?.some(perm => accessPermission.includes(perm))) {
                            // changing the route based on the permissions as either an admin or user
                            if (!hasAccessToOrgPayroll && route.key === 'payroll')
                                route.route = 'payroll/pay_history?accountType=personal'
                            if (!hasAccessToOrgContract && route.key === 'contracts')
                                route.route = 'contract/contract_list?accountType=personal'

                            return (
                                <div className='mt-4' key={route.key}>
                                    <div className='flex gap-3 flex-col'>
                                        {route.key === 'payroll' && (
                                            <Accordion type='single' collapsible>
                                                <AccordionItem value='item-1' className='border-none'>
                                                    <AccordionTrigger
                                                        className={cn('font-normal  py-2 px-2')}
                                                        righticon={<ChevronDown className='w-4 h-4 chevron' />}
                                                    >
                                                        <span className='flex items-center gap-3 px-3'>
                                                            {route.icon && (
                                                                <route.icon className={'fill-white stroke-black/90'} />
                                                            )}
                                                            Payroll
                                                        </span>
                                                    </AccordionTrigger>
                                                    <AccordionContent>
                                                        <div className='w-full border-l border-gray8/60'>
                                                            {route?.children?.map(routeChild => (
                                                                <SidebarLink
                                                                    child
                                                                    onRouteChange={onRouteChange}
                                                                    {...routeChild}
                                                                />
                                                            ))}
                                                        </div>
                                                    </AccordionContent>
                                                </AccordionItem>
                                            </Accordion>
                                        )}
                                        {route.key !== 'payroll' && (
                                            <SidebarLink {...route} onRouteChange={onRouteChange} />
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>

            <div className='mt-2 '>
                <div className='flex flex-col gap-3 px-3 py-5 border-t border-b border-[#DADCE0] text-sm mb-3'>
                    <SidebarLink
                        {...settingsRoute}
                        route={!hasAdminPerm ? 'settings/user' : settingsRoute.route}
                        parentRoute='settings'
                        onRouteChange={onRouteChange}
                    />
                    <div
                        className={'px-3 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#F9F6FF] pl-5'}
                        onClick={handleLogOut}
                    >
                        <LogOutIcon strokeWidth={1.2} size={19} />
                        Log out
                    </div>
                    {isBizpendAdmin && (
                        <div className='relative '>
                            {pathname.includes('/dashboard/business-directory') ? (
                                <div className='w-full h-[60px] bg-[#f3eefb]'></div>
                            ) : (
                                <BusinessDirectoryIcon className='rounded-2xl w-full mx-auto' />
                            )}
                            <button
                                className={cn(
                                    location.pathname.includes(`/dashboard/business-directory`)
                                        ? `text-brand`
                                        : 'text-white',
                                    'absolute inset-0 flex items-center justify-center font-medium gap-3'
                                )}
                                onClick={() =>
                                    navigate(`/dashboard/business-directory/manage_business`, {
                                        state: { show: isBizpendAdmin },
                                    })
                                }
                            >
                                <PersonalCardIcon
                                    className={cn(
                                        location.pathname.includes('/dashboard/business-directory')
                                            ? `fill-[#454ADE]`
                                            : ''
                                    )}
                                />
                                Business directory
                            </button>
                        </div>
                    )}
                </div>{' '}
            </div>
        </div>
    )
}
export default NewSidebar

const SidebarLink = ({
    onRouteChange,
    ...item
}: SidebarItemType & { onRouteChange: (a: string) => void; child?: boolean }) => {
    const location = useLocation()
    const handleRouteSwitch = () => {
        onRouteChange(item.name)
    }

    const isActive = (route: string) => {
        return location.pathname.startsWith(route)
    }

    return (
        <Link
            to={item.route}
            className={cn(
                isActive(`/dashboard/${item?.parentRoute ?? item?.route}`)
                    ? `text-[#454ADE] font-semibold`
                    : ' text-[#202020]',
                'flex items-center hover:bg-[#F9F6FF] relative'
            )}
            onClick={handleRouteSwitch}
            id={`${item.name.toLowerCase()}-sidebar`}
        >
            {isActive(`/dashboard/${item?.parentRoute ?? item?.route}`) && (
                <motion.span
                    className={'absolute inset-0  bg-[#F9F6FF] rounded-lg text-[#454ADE]'}
                    layoutId='sidebar__links'
                />
            )}
            <span
                className={`px-3 py-2 flex items-center gap-3 cursor-pointer hover:bg-[#F9F6FF] pl-5 z-10 ${item?.child ? (!isActive(`/dashboard/${item?.parentRoute}`) ? 'text-gray7/90 !pl-14' : '!pl-14') : ''}`}
            >
                {item.icon && (
                    <item.icon
                        className={cn(
                            isActive(`/dashboard/${item?.parentRoute ?? item?.route}`)
                                ? ` fill-[#454ADE] stroke-[#fff]`
                                : 'fill-[#fff] stroke-[#202020]',
                            'z-10'
                        )}
                    />
                )}
                {item.name}
            </span>
        </Link>
    )
}

import { CancelIcon } from '@/assets/assets.export'
import ModalWrapper from '../../ModalWrapper'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { useRef, useState } from 'react'
import { capitalizeText } from '@/utils/string'
import { EmployeeType } from '@/types/org'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { useAssignLeave, useUpdateLeave } from '@/services/payroll/mutations'
import { useAuthContext } from '@/context'
import { toastHandler } from '@/components/utils/Toast'
import { LeaveRequestType } from '@/services/payroll/types'
import { addDays, format } from 'date-fns'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import DatePicker from 'react-datepicker'
import { UploadFileToS3 } from '@/components/UploadFileToS3'

type AssignLeaveProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    orgMembers: EmployeeType[]
    edit?: boolean
    leaveData?: LeaveRequestType
}

const AssignLeaveModal = ({ closeModal, orgMembers, edit, leaveData }: AssignLeaveProps) => {
    const formEmptyState = {
        account: leaveData?.account._id ?? '',
        leave_type: leaveData?.leave_type ?? '',
        start_date: leaveData?.start_date ? format(new Date(leaveData.start_date?.slice(-1)?.[0]), 'yyyy-MM-dd') : '',
        end_date: leaveData?.end_date ? format(new Date(leaveData.end_date?.slice(-1)?.[0]), 'yyyy-MM-dd') : '',
        reason: leaveData?.reason ?? '',
        proof_url: leaveData?.proof_url ?? '',
    }
    const { selectedAccount } = useAuthContext()
    const calendarRef1 = useRef<DatePicker>(null)
    const calendarRef2 = useRef<DatePicker>(null)

    const [formState, setFormState] = useState(formEmptyState)

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const [paidLeave, setPaidLeave] = useState(edit ? (leaveData?.is_paid_leave ? 'paid' : 'unpaid') : 'paid')

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const { mutate: assignLeaveFn, isLoading } = useAssignLeave({
        queryParams: { org_id: selectedAccount?.org?.id as string },
        onSuccess: () => {
            closeModal(false)
            setFormState(formEmptyState)
            toastHandler({ message: 'Leave successfully assigned', state: 'success' })
        },
    })

    const { mutate: updateLeaveFn, isLoading: isUpdatingLeave } = useUpdateLeave({
        queryParams: { leave_id: leaveData?._id as string },
        onSuccess: () => {
            closeModal(false)
            setFormState(formEmptyState)
            toastHandler({ message: 'Leave has been updated successfully', state: 'success' })
        },
    })

    const handleAssignLeave = (edit?: boolean) => {
        const data = {
            start_date: formState.start_date,
            end_date: formState.end_date,
            leave_type: formState.leave_type,
            account: formState.account,
            reason: formState.reason,
            is_paid_leave: paidLeave === 'paid' ? true : false,
        }
        if (edit) {
            updateLeaveFn(data)
            return
        }
        assignLeaveFn(data)
    }
    const disableBtn = !(formState.account && formState.start_date && formState.end_date && formState.leave_type)

    return (
        <ModalWrapper formId='leaveForm' shouldConfirmClose closeModal={() => closeModal(false)}>
            <main className='h-[34.5rem] lg:h-[37rem] w-screen lg:w-[44rem]'>
                <div className='flex items-center border-b border-[#DADCE0] p-4 '>
                    <span className='flex-1 text-center text-xl font-medium'>{edit ? 'Edit' : 'Assign'} leave</span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </div>

                <form
                    id='leaveForm'
                    onSubmit={(e: React.FormEvent) => e.preventDefault()}
                    className='max-h-[400px] lg:max-h-[440px] overflow-scroll'
                >
                    <div className='p-6 px-4 lg:px-10 pb-3 '>
                        <div className={`flex flex-col md:flex-row gap-x-5 flex-wrap mb-6`}>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Name
                                    <span className=''>*</span>
                                </label>
                                <Select
                                    name='name'
                                    onValueChange={value => {
                                        setFormState(prevState => ({
                                            ...prevState,
                                            account: value,
                                        }))
                                    }}
                                    value={formState.account}
                                    disabled={edit}
                                >
                                    <SelectTrigger className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                        {(formState.account as keyof typeof formState) ? (
                                            <SelectValue />
                                        ) : (
                                            <span>Select user</span>
                                        )}
                                    </SelectTrigger>
                                    <SelectContent className='w-[18.9rem] max-h-[200px] overflow-scroll'>
                                        {orgMembers.map(member => (
                                            <SelectItem value={member.id}>
                                                {capitalizeText(member.user.first_name)}{' '}
                                                {capitalizeText(member.user.last_name)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Leave type
                                    <span className=''>*</span>
                                </label>
                                <Select
                                    name='name'
                                    onValueChange={value => {
                                        setFormState(prevState => ({
                                            ...prevState,
                                            leave_type: value,
                                        }))
                                    }}
                                    value={formState.leave_type}
                                >
                                    <SelectTrigger className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                        {(formState.leave_type as keyof typeof formState) ? (
                                            <SelectValue />
                                        ) : (
                                            <span>Sick days, vacation etc.</span>
                                        )}
                                    </SelectTrigger>
                                    <SelectContent className='w-[18.9rem] max-h-[200px] overflow-scroll'>
                                        <SelectItem value='sick_days'>Sick days</SelectItem>
                                        <SelectItem value='vacation'>Vacation</SelectItem>
                                        <SelectItem value='conference'>Conference</SelectItem>
                                        <SelectItem value='parental'>Parental</SelectItem>
                                        <SelectItem value='miscellaneous'>Miscellaneous</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row gap-[1.8rem] mb-6'>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Start date
                                    <span className=''>*</span>
                                </label>

                                <CustomDatePicker
                                    ref={calendarRef1}
                                    placeholderText='Choose start date'
                                    onChange={date => {
                                        setFormState(prev => ({
                                            ...prev,
                                            start_date: date as unknown as string,
                                        }))
                                    }}
                                    minDate={addDays(new Date(), 1)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode='select'
                                    selected={formState.start_date ? new Date(formState.start_date) : undefined}
                                />
                            </div>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    End date
                                    <span className=''>*</span>
                                </label>

                                <CustomDatePicker
                                    ref={calendarRef2}
                                    placeholderText='Choose end date'
                                    onChange={date => {
                                        setFormState(prev => ({
                                            ...prev,
                                            end_date: date as unknown as string,
                                        }))
                                    }}
                                    minDate={formState.start_date as unknown as Date}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode='select'
                                    selected={formState.end_date ? new Date(formState.end_date) : undefined}
                                />
                            </div>
                        </div>
                        {formState.leave_type === 'sick_days' && (
                            <div className='mb-6 '>
                                <UploadFileToS3
                                    name='sick_days'
                                    label='Upload medical report'
                                    setValue={url => setFormState({ ...formState, proof_url: url })}
                                />
                            </div>
                        )}

                        <div className='flex flex-col md:flex-row gap-[1.8rem] mb-6'>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Reason for leave
                                    <span className='ml-auto text-xs'>{formState?.reason?.length}/120</span>
                                </label>
                                <Textarea
                                    name='reason'
                                    placeholder='Write additional notes'
                                    className='border border-[#DADCE0] pt-4 min-h-[6.5rem] text-18px resize-none'
                                    value={formState.reason}
                                    maxLength={120}
                                    onChange={handleInputChange}
                                    ref={textareaRef}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row gap-[1.8rem] mb-6'>
                            <div className='flex flex-col flex-1 gap-2'>
                                <label className='flex items-center gap-1'>Is this leave paid or unpaid?</label>
                                <RadioGroup
                                    className='flex gap-10 mt-3 disabled:opacity-50'
                                    onValueChange={radio_type => {
                                        setPaidLeave(radio_type)
                                    }}
                                    defaultValue=''
                                    value={paidLeave}
                                >
                                    <label htmlFor='paid' className={`flex gap-2 items-center cursor-pointer`}>
                                        <RadioGroupItem value={'paid'} id='paid' />
                                        Paid leave
                                    </label>
                                    <label htmlFor='unpaid' className={`flex gap-2 items-center  cursor-pointer`}>
                                        <RadioGroupItem value='unpaid' id='unpaid' />
                                        Unpaid leave
                                    </label>
                                </RadioGroup>
                                {paidLeave === 'unpaid' && (
                                    <div className='bg-[#EFEFF9] p-3 mt-1 rounded-xl'>
                                        <h1 className='text-[#202020] font-medium'>Note</h1>
                                        <p className='text-sm text-[#5E5E5E]'>
                                            An unpaid leave for this individual means that{' '}
                                            <span className='font-semibold'> 3 days</span> will be deducted from their
                                            total number of work days <span className='font-semibold'>(25 days)</span>.
                                            This will deduct this user’s salary payout for the next payroll.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
                <div className='border-t border-[#DADCE0] py-4 px-10'>
                    <div className='flex justify-end items-center gap-5'>
                        <Button
                            className='bg-transparent text-[#202020] hover:bg-transparent hover:border hover:border-[#202020]'
                            onClick={() => closeModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={edit ? isUpdatingLeave : isLoading}
                            disabled={disableBtn}
                            onClick={() => handleAssignLeave(edit)}
                        >
                            {edit ? 'Update leave' : 'Assign leave'}
                        </Button>
                    </div>
                </div>
            </main>
        </ModalWrapper>
    )
}

export default AssignLeaveModal
